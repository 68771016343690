import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Cancer.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Cancer Career
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/cancer"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Cancer</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>             
              <h4 className="text-sm"></h4>

              <h4 className="text-sm">Cancer Career</h4>
              <h4 className="mx-2 text-sm"></h4>             
              <h4 className="text-sm"></h4>


            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Cancer Career </h1>
                <h2 className="text-sm md:text-base ml-4">Jun 22 - Jul 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-love");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-nature");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-man");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-traits");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-facts");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Cancer Career</p><br/>
           <br></br>
           <p className="font-bold text-orange-500 inline-block mr-2">Career Mantra:</p>I do care that's why I'm successful<br/>
           <p className="font-bold text-orange-500 inline-block mr-2">Lessons to be given:</p>helping hand, instinct, intellectuality, imagination,<br/>
           <p className="font-bold text-orange-500 inline-block mr-2">Things that are mandatory to learn:</p>how to welcome gracefully, control your mood swings, think twice before speaking, and control sensitiveness, and tolerance.<br/>
           <br></br>
           Though you are expressive, you are extremely committed, focused, and determined in the workplace. You are very kind and compassionate by nature, caregiving and nurturing career options suit them best. You need to feel that you are creating some difference in the association. Your contribution can be little but it gives you pleasure. You love to work independently, without any barriers, and hate to follow the format of an organization.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Career Choices</p><br/>
           You are scared of too many commitments but will perform important work with accuracy, low risk, on time, and successfully. there are so many ups and down in your career. You are intelligent, innovative, creative, and incredibly good at reading people and also getting in advance what they need. Your instincts are also very strong. The mixture of four talents makes you victorious in your career. It also allows you to make excellent decisions, be a good problem solver, and be a leader. <br/>
           <br></br>
           Your associates and colleagues respect you as a leader. It is because you know how to fix things, teach colleagues and workers, and promote their careers. You possess extraordinary people skills and hence you select committed and attentive people in your team who will expand your career as well as theirs. You choose to make a career in a domain that needs understanding and assistance. <br/>
           <br></br>
           You will definitely rise and shine in your career if you let go of your shyness and insecurities. You will initiate those things and tasks the moment you are assigned, this gesture of hard work makes you different from others. You will not quit until you have completed them. You can powerfully handle your career due to your perseverance.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">What to choose?</p><br/>
           Cancer would choose anything that is latest, evolved, or fields that are correlated to science and technology. As they need intellectual stimulus, they are more willing to choose the domains wherein there is a chance of debate or a possibility to question set standards. Henceforth, a perfect career choice for Cancer would be a position that nourishes a fair bit of flexibility and the possibility to work for a good cause. Although, their bizarre dispositions tend to make them adamant and they often feel that the individuals around them are not very open-minded or are not capable of sensing their bright ideas. This mindset might cause these innovative and rational individuals few troubles on the career front, especially when they need to work in large groups or teams. Moreover, we recommend you Talk to a Bodhi Astrologer to decide on your career-related problems.<br/>
           You love performing hard work and your instincts help you to know that a position is well done. You choose to work in your manner and excel in surroundings where you can move your agenda and schedule.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Best Career Options</p><br/>
           Cancer will usually create their career in areas involving caregiving and promotion such as human resources, nursing, teaching, journaling, child care, housekeeping, and gardening. You are classic and patriotic which makes you suitable for politics as well. You can be fantastic artists, writers, songwriters, actors, and media due to powerful instincts and opinions. You can be good leaders, orators, preachers, and administrators. You excel well in occupations related to water, airlines, navy, shipping, and travel. You can also make a career as a corporate director, editor, author, gallery owner, interior designer, women’s rights lawyer, family therapist, computer techniques analyst or programmer, home-based business, or corporate strategist. Know the astonishments waiting for you in your career in advancement and be prepared to push your limitations to achieve them. <br/>
           <br></br>
           <p>What are you waiting for?</p>
           Hurry up and get your personalized career report now!<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Cancer and Money</p><br/>
           You infrequently invest money in mindless projects. You don’t like to bear heavy risks. Economic security is particularly important for you and you are very careful while investing. You are provident, intelligent, and always think about the future. You are not capricious in spending money. You usually plan and recheck your expenses and rarely expend money unwisely.
           This side of your personality shows how Financial security is important to you. Therefore, they are continually saving up for a rainy day. Due to their strong intuition, making money is like child’s play for Cancerians. They are leaned to plan the right procedures and thus successfully close deals. Cancer people view capital as a measurement of their worth.<br/>
           <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
